<template>
  <!-- 班级管理页面-->
  <div class="classManage">
    <div class="f-c classManage-tool">
      <button
        class="f-l"
        style="width: 82px"
        :loading="loading"
        @click="
          formdata.className = '';
          formdata.id = '';
          classVisible = true;
          isedit = false;
        "
      >
        <img
          src="@/assets/images/icon/icon_add.png"
          class="studentManage-tool-btnicon"
        />
        <span>{{ $t("el.common.create") }}</span>
      </button>
      <!-- <el-button type="primary"
                 class="f-l"
                 style="width:82px;height:32px;padding:7px 0 10px 0;"
                 icon="el-icon-circle-plus-outline"
                 @click="classVisible = true;isedit=false">新建</el-button> -->
      <div class="f-r classManage-tool-title" style="max-width: 80%">
        *{{ $t("el.classManage.classFunction") }}
      </div>
    </div>
    <!-- 表格 -->
    <div class="classManage-table">
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        :header-cell-class-name="headerStyle"
        :cell-class-name="cellStyle"
        style="width: 100%"
      >
        <template slot="empty">
          <div style="padding-top: 111px; line-height: 0">
            <img
              src="@/assets/images/pic_empty_people@2x.png"
              alt=""
              style="width: 270px"
            />
          </div>
          <div
            style="
              color: rgba(100, 108, 149, 0.25);
              font-size: 18px;
              font-weight: 600;
              letter-spacing: 1px;
              padding-bottom: 120px;
            "
          >
            {{ $t("el.common.noPerson") }}
          </div>
        </template>
        <el-table-column
          prop="classTypeStr"
          :label="$t('el.classManage.classType')"
          :show-overflow-tooltip="true"
          min-width="20%"
        >
        </el-table-column>
        <el-table-column
          prop="className"
          :label="$t('el.classManage.className')"
          :show-overflow-tooltip="true"
          min-width="20%"
        >
        </el-table-column>
        <el-table-column
          prop="stuNumber"
          :label="$t('el.classManage.stuNumber')"
          :show-overflow-tooltip="true"
          min-width="20%"
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          :label="$t('el.classManage.createTime')"
          :show-overflow-tooltip="true"
          min-width="24%"
        >
        </el-table-column>
        <el-table-column :label="$t('el.common.operate')" min-width="36%">
          <template slot-scope="scope">
            <el-button
              type="text"
              :loading="loading"
              @click="toStudent(scope.row)"
              >{{ $t("el.classManage.studentManage") }}</el-button
            >
            <el-button
              v-if="~~scope.row.classType === 1"
              type="text"
              :loading="loading"
              style="margin: 0 30px 0 40px"
              @click="editClass(scope.row)"
              >{{ $t("el.common.edit") }}</el-button
            >
            <el-button
              v-if="~~scope.row.classType === 1"
              type="text"
              :loading="loading"
              @click="
                formdata.id = scope.row.id;
                deleteVisible = true;
              "
              >{{ $t("el.common.delete") }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--分页  -->
    <div class="classManage-page">
      <el-pagination
        layout="prev, pager, next"
        :current-page.sync="params.page"
        :page-size="params.limit"
        :total="total"
      >
      </el-pagination>
      <span class="el-pagination__total"
        >{{ $t("el.common.totalname") }}{{ Math.ceil(total / params.limit)
        }}{{ $t("el.common.pages") }}&nbsp;&nbsp;{{ total
        }}{{ $t("el.common.items") }}
      </span>
    </div>
    <!-- 删除弹窗 -->
    <el-dialog
      width="464px"
      class="delete"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="false"
      :visible.sync="deleteVisible"
    >
      <div slot="title" class="red delete-header">
        <img src="@/assets/images/icon/msgbox_delete.png" />
        <span class="delete-header-text">{{
          $t("el.common.deleteConfirm")
        }}</span>
      </div>
      <div class="delete-content" style="word-break: break-word">
        {{ $t("el.classManage.deleteClassTips") }}
      </div>
      <span slot="footer" class="delete-footer">
        <el-button
          type="primary"
          size="medium"
          :loading="loading"
          @click="deleteItem"
          >{{ $t("el.common.confirm") }}</el-button
        >
        <el-button @click="deleteVisible = false" size="medium">{{
          $t("el.common.cancel")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 新增编辑弹窗 -->
    <el-dialog
      width="464px"
      class="class"
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :modal-append-to-body="false"
      :visible.sync="classVisible"
    >
      <div slot="title" class="red">
        {{
          isedit
            ? $t("el.classManage.editClass")
            : $t("el.classManage.newClass")
        }}
      </div>
      <div class="el-dialog__content class-content">
        <div>
          <span class="class-content-redtip"> * </span>
          <span class="class-content-label"
            >{{ $t("el.classManage.className") }} :
          </span>
          <el-input
            :placeholder="$t('el.common.pleaseEnter')"
            v-model="formdata.className"
            class="class-content-input"
          ></el-input>
          <div class="class-content-warn" v-if="ischeck.className">
            <span>{{ $t("el.classManage.enterClassName") }}</span>
          </div>
        </div>
      </div>
      <span slot="footer" class="class-footer">
        <el-button
          type="primary"
          :loading="loading"
          size="medium"
          @click="sureClass"
          >{{ $t("el.common.confirm") }}</el-button
        >
        <el-button @click="classVisible = false" size="medium">{{
          $t("el.common.cancel")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTableList,
  addClass,
  editClass,
  deletedClass,
} from "@/api/home/classmanage";
export default {
  name: "classManage",
  // 注册组件
  components: {},
  // 定义数据
  data() {
    return {
      loading: false, // 列表加载状态
      tableData: [],
      deleteVisible: false, // 删除弹窗
      classVisible: false, // 新增编辑班级弹窗
      isedit: false, // 是否是编辑
      formdata: {
        id: "",
        className: "",
      },
      ischeck: {
        className: false,
      },
      params: {
        limit: 10,
        offset: 0,
        page: 1,
      },
      total: 0,
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {
    // 监听页数
    "params.page"(newVal, oldVal) {
      // 改变参数
      this.params.offset = this.params.limit * (this.params.page - 1);
      this.getData();
    },
  },
  // 创建完成钩子
  created() {
    this.getData();
  },
  // 挂在完成钩子
  mounted() {},
  // 方法定义
  methods: {
    headerStyle({ row, rowIndex }) {
      // 表头样式
      return "bskHeader";
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      // 表格样式
      return "bskCell";
    },
    sureClass() {
      // 新增编辑确认
      var check = "";
      check = this.$utils.check(this.formdata, [
        { key: "className", require: true, msg: "className" },
      ]);
      if (check === 1) {
        for (var i in this.ischeck) {
          this.ischeck[i] = false;
        }
        if (this.isedit) {
          this.loading = true;
          editClass(this.formdata)
            .then(({ data }) => {
              this.$message({
                type: "success",
                message: this.$t("el.classManage.editSuccess"),
              });
              this.classVisible = false;
              this.getData();
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          this.loading = true;
          addClass(this.formdata)
            .then(({ data }) => {
              this.$message({
                type: "success",
                message: this.$t("el.classManage.addSuccess") + "!",
              });
              this.classVisible = false;
              this.getData();
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }
      } else {
        this.ischeck[check] = true;
      }
    },
    toStudent(row) {
      if (~~row.classType === 1) {
        // 学员管理
        this.$router.push({
          name: "studentManage",
          query: {
            id: row.id,
            name: row.className,
          },
        });
      } else {
        // 学员管理
        this.$router.push({
          name: "classStudentManage",
          query: {
            classId: row.id,
            schoolCode: row.schoolCode,
            gradeId: row.gradeId,
            name: row.className,
          },
        });
      }
    },
    getData() {
      // 获取列表数据
      this.loading = true;
      getTableList(this.params)
        .then(({ data }) => {
          this.tableData = data.records;
          this.total = data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    editClass(row) {
      this.$utils.mergeDefault(this.formdata, row);
      this.isedit = true;
      this.classVisible = true;
    },
    deleteItem() {
      this.loading = true;
      deletedClass({ id: this.formdata.id })
        .then(({ data }) => {
          this.$message({
            type: "success",
            message: this.$t("el.classManage.deleteSuccess") + "!",
          });
          this.deleteVisible = false;
          this.getData();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.classManage {
  background: #fff;
  padding: 30px 40px 42px 40px;
  border-radius: 4px;
  min-height: 565px;
  box-sizing: border-box;
  &-tool {
    height: 32px;
    line-height: 32px;
    margin-bottom: 25px;
    &-title {
      font-size: 14px;
      // font-family: "MicrosoftYaHei";
      color: rgba(100, 108, 149, 1);
      line-height: 32px;
    }
    button {
      font-size: 12px;
      // font-family: "MicrosoftYaHei";
      color: rgba(249, 249, 249, 1);
      height: 32px;
      display: flex;
      background: @themeBlue;
      border-radius: 5px;
      float: left;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      outline: none;
      img {
        width: 14px;
        height: 14px;
        margin-right: 6px;
      }
    }
  }
  &-table {
    /deep/.bskHeader {
      background-color: #f5f6f7 !important;
      color: #323232;
      text-align: center !important;
      font-size: 14px;
      // font-family: "MicrosoftYaHei";
      font-weight: normal;
      height: 50px;
      padding: 0;
    }
    /deep/.bskCell {
      text-align: center !important;
      color: #707276;
      font-size: 14px;
      height: 46px;
      padding: 0;
      .cell {
        white-space: nowrap;
      }
    }
    /deep/.el-button--text {
      border: 0;
      color: @themeBlue;
    }
    /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
      background: #f1f6ff;
    }
  }

  &-page {
    padding-top: 38px;
    text-align: center;
    /deep/ .el-pager li {
      height: 30px;
      line-height: 30px;
      width: 30px;
      min-width: 30px;
      border-radius: 50%;
      padding: 0 0px;
      margin: 0 5px;
      font-size: 13px;
      // font-family: "SourceHanSansCN-Medium", "SourceHanSansCN";
      font-weight: 400;
      color: rgba(40, 40, 40, 1);
    }
    /deep/ .el-pager li.active {
      background: @themeBlue;
      color: #fff;
      font-size: 13px;
      // font-family: "SourceHanSansCN-Medium", "SourceHanSansCN";
    }
    /deep/.el-pagination__total {
      font-size: 12px;
      // font-family: "MicrosoftYaHei";
      color: rgba(40, 40, 40, 1);
    }
    /deep/.el-pagination button {
      height: 30px;
      line-height: 30px;
    }
    /deep/ .el-pagination button:hover {
      color: @themeBlue;
    }
  }
  /deep/ .el-table th.gutter {
    display: table-cell !important;
  }
}
.delete {
  /deep/ .el-dialog__header {
    padding: 24px 20px 10px;
  }
  &-header {
    display: flex;
    justify-content: center;
    img {
      width: 20px;
      margin-right: 8px;
    }
    &-text {
      font-size: 18px;
      // font-family: "MicrosoftYaHei-Bold", "MicrosoftYaHei";
      font-weight: bold;
      color: rgba(245, 34, 45, 1);
    }
  }
  &-content {
    text-align: center;
    padding: 23px 25px 45px;
    font-size: 19px;
    // font-family: "MicrosoftYaHei";
    color: rgba(100, 108, 149, 1);
    line-height: 37px;
    letter-spacing: 1px;
  }
  /deep/ .el-dialog__footer {
    padding-bottom: 30px;
  }
  button {
    width: 84px;
    border-radius: 5px;
    margin: 0 18px;
  }
}
.class {
  &-content {
    padding-left: 9px;
    height: 118px;
    &-redtip {
      font-size: 16px;
      // font-family: MicrosoftYaHei;
      color: rgba(245, 34, 45, 1);
      line-height: 21px;
    }
    &-label {
      font-size: 16px;
      // font-family: MicrosoftYaHei;
      color: rgba(70, 70, 70, 1);
      line-height: 21px;
    }
    &-input {
      width: 260px;
    }
    &-warn {
      font-size: 14px;
      // font-family: "MicrosoftYaHei";
      color: rgba(245, 34, 45, 1);
      line-height: 19px;
      margin: 8px 0 0 88px;
    }
  }
  button {
    width: 84px;
    border-radius: 5px;
    margin: 0 18px;
  }
  /deep/ .el-dialog__footer {
    padding-bottom: 30px;
  }
}
.classManage-page {
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
}
</style>
