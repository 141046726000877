import axios from "@/plugins/axios.js";

/**
 * 课程列表
 * @return {Promise}
 */
export function getTableList(data) {
  return axios.get("/classManage/findClasses", {
    params: data,
  });
}

/**
 * 新增课程
 * @return {Promise}
 */
export function addClass(data) {
  return axios.post("/classManage/addClass", data);
}

/**
 * 新增课程
 * @return {Promise}
 */
export function editClass(data) {
  return axios.get("/classManage/updateClass", {
    params: data,
  });
}

/**
 * 删除课程
 * @return {Promise}
 */
export function deletedClass(data) {
  return axios.get("/classManage/deletedClass", {
    params: data,
  });
}
