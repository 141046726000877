<template>
  <div class="course-class">
    <p class="course-class-tips">
      <label>*</label>{{ $t("el.courseClassManage.classIntro") }}
    </p>
    <xm-table
      ref="xmTable"
      :fatch-method="fatchMethod"
      :autoLoad="true"
      :columns="columns"
      :formOptions="{
        hiddenDefaultFormBtn: true,
      }"
    >
      <template slot="otherHandle" slot-scope="scope">
        <el-button type="text" @click="manageStudent(scope.row)">{{
          $t("el.courseClassManage.studentManage")
        }}</el-button>
      </template>
    </xm-table>
  </div>
</template>

<script>
export default {
  name: "manageCourseClass",
  data() {
    return {};
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t("el.courseClassManage.class"),
          prop: "className",
        },
        {
          label: this.$t("el.courseClassManage.course"),
          prop: "courseName",
        },
        {
          label: this.$t("el.courseClassManage.school"),
          prop: "schoolName",
        },
        {
          label: this.$t("el.courseClassManage.stuNumber"),
          prop: "thisClassNum",
        },
        {
          label: this.$t("el.common.operate"),
          slotName: "otherHandle",
        },
      ];
    },
  },
  methods: {
    fatchMethod(params) {
      let data = params;
      delete data.page;
      delete data.total;
      return this.$store
        .dispatch("getMyCourseClassList", data)
        .then((result) => {
          return result;
        });
    },
    // 跳转学员管理页面
    manageStudent(item) {
      this.$router.push({
        name: "manageCourseClassStudent",
        query: {
          courseId: item.courseId,
          classId: item.id,
          className: item.className,
          schoolCode: item.schoolCode,
          tenantInfoId: item.tenantInfoId,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.course-class {
  background: #fff;
  padding: 30px 40px 42px 40px;
  border-radius: 4px;
  min-height: 565px;
  box-sizing: border-box;
  /deep/ .el-form--inline {
    display: none;
  }
  &-tips {
    font-size: 14px;
    color: #999;
    text-align: end;
  }
}
</style>
