<template>
  <div class="c-dialog">
    <el-dialog
      :title="optionsData.dialogTitle"
      :visible="visible"
      v-bind="optionsData"
      :width="optionsData.width || '600px'"
      :close-on-click-modal="optionsData.closeOnClickModal || false"
      :close-on-press-escape="optionsData.closeOnPressEscape || false"
      :modal-append-to-body="optionsData.modalAppendToBody || false"
      :left="optionsData.left ? true : false"
      @close="closeDialog"
    >
      <p
        v-if="optionsData.dialogTitleText !== undefined"
        slot="title"
        class="dialog-title"
      >
        {{ optionsData.dialogTitle }}
        <span class="dialog-title-text">{{ optionsData.dialogTitleText }}</span>
      </p>
      <slot name="customView" />
      <xm-form
        v-if="!hideForm"
        ref="form"
        :form-items="formItems"
        :form-options="newFormOptions"
        :form-data="queryParams"
        @submit.native.prevent
        @onSelectChange="onSelectChange"
      >
        <el-form-item
          slot="formBtn"
          class="btn-content"
          style="width: 100%; text-align: right"
        >
          <el-button
            v-if="newFormOptions && !newFormOptions.hiddenDefaultFormBtn"
            size="small"
            @click="closeDialog()"
            >{{ $t("el.common.cancel") }}</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="submitForm('form')"
            class="mt20"
            v-if="newFormOptions && !newFormOptions.hiddenDefaultFormBtn"
            >{{ $t("el.common.confirm") }}</el-button
          >

          <slot name="formBtn" />
        </el-form-item>
      </xm-form>
    </el-dialog>
  </div>
</template>

<script>
import XmForm from "../../global/xm-form.vue";
export default {
  name: "CDialog",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    optionsData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 是否隐藏表单
    hideForm: {
      type: Boolean,
      default: false,
    },
    /**
     * 表单配置项
     */
    formItems: {
      type: Array,
      default: null,
    },
    /**
     * 表配置属性
     */
    formOptions: {
      type: Object,
      default: () => {},
    },
    /**
     * 表单参数
     */
    formQueryParams: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    XmForm,
  },
  data() {
    var queryParams = {};
    this.formItems &&
      this.formItems.forEach((item) => {
        queryParams[item.prop] = "";
      });
    return {
      queryParams, // 查询参数
    };
  },
  computed: {
    /**
     * 继承表单配置
     */
    newFormOptions() {
      return {
        inline: true,
        ...this.formOptions,
      };
    },
  },
  watch: {},
  mounted() {
    //   this.dialogVisible = this.visible;
    //   console.log("dialogVisible",this.dialogVisible);
  },
  methods: {
    // 关闭dialog
    closeDialog() {
      this.$refs["form"] && this.$refs["form"].resetForm();
      this.$emit("close-dialog");
    },
    // 提交
    submitForm(formName) {
      this.$emit(
        "submit-form",
        this.$refs[formName].$refs["form"],
        this.queryParams
      );
    },
    onSelectChange(item, id) {
      this.$emit("onSelectChange", item, id);
    },
    // getQueryParams() {
    //   return this.queryParams;
    // },
  },
};
</script>

<style lang="less" scoped>
.c-dialog {
  /deep/ .el-dialog__header {
    .dialog-title {
      display: flex;
      align-items: flex-end;
      .dialog-title-text {
        display: inline-block;
        margin-left: 12px;
        font-size: 14px;
        color: #999;
      }
    }
  }
  /deep/ .el-dialog__body {
    padding: 30px 20px 0 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
}
.ml10 {
  margin-left: 10px;
}
.ml5 {
  margin-left: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
</style>
