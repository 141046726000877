<template>
  <div v-loading="allLoading" class="course-class-student">
    <router-menu-title :isCustomize="true" :routerTitleList="routerTitleList">
      <template slot="titleContent">
        <span style="color: #6049ff">{{ routerTitle }}</span>
      </template>
    </router-menu-title>
    <!-- 左边学生列表 -->
    <div class="right-list">
      <xm-table
        ref="xmTable"
        :fatch-method="fatchMethod"
        :form-items="formItems"
        :columns="columns"
        :formOptions="{
          hiddenDefaultFormBtn: true,
        }"
        :autoLoad="false"
        :tableOptions="{
          height: tableHeight,
        }"
      >
        <template slot="formBtn">
          <el-button
            type="primary"
            class="teachManage-tool-btnicon"
            @click="addStudent()"
            >{{ $t("el.courseClassManage.addStudent") }}</el-button
          >
        </template>

        <template slot="otherHandle" slot-scope="scope">
          <el-button
            size="small"
            type="text"
            @click="deleteAttendClass(scope.row)"
            >{{ $t("el.courseClassManage.remove") }}</el-button
          >
        </template>
      </xm-table>
    </div>
    <!-- 学生弹框 -->
    <c-dialog
      ref="dialogStudent"
      :formItems="studentFormItems"
      :formOptions="studentFormOptions"
      :visible="showStudentDialog"
      :optionsData="optionsData"
      @submit-form="submitStudentForm"
      @close-dialog="closeStudentDialog"
    >
    </c-dialog>
  </div>
</template>

<script>
import routerMenuTitle from "../../../components/biz-components/router-menu-title/index.vue";
import CDialog from "../../../components/biz-components/CDialog/index.vue";
export default {
  name: "manageCourseClassStudent",
  data() {
    return {
      routerTitleList: [],
      fromTitle: "",
      // routerTitle: "",
      schoolName: "",
      className: "",
      courseId: "",
      schoolCode: "",
      classList: [], // 班级列表
      studentTree: [],
      allLoading: false,
      tableHeight: undefined,
      showStudentDialog: false, // 学生弹框
      optionsData: {}, // 弹框默认数据
      studentFormOptions: {
        // 学生表单弹框配置
        rules: {
          classId: [
            {
              required: true,
              message: this.$t("el.courseClassManage.classRules"),
              trigger: "change",
            },
          ],
          studentIds: [
            {
              required: true,
              message: this.$t("el.courseClassManage.studentRules"),
              trigger: "change",
            },
          ],
        },
      },
      tenantInfoId: "",
    };
  },
  components: {
    routerMenuTitle,
    CDialog,
  },
  created() {
    this.init();
  },
  // 计算属性
  computed: {
    // 定义查询表单
    formItems() {
      return [
        {
          itemType: "inputSearch",
          prop: "keyword",
          placeholder: this.$t("el.courseClassManage.enterNameIDStudentID"),
          label: "",
          clearable: true,
          formStyle: "margin-left:auto;",
          itemStyle:
            "width:" + this.$t("el.courseClassManage.placeholderWidth"),
        },
      ];
    },
    // 学生表单item
    studentFormItems() {
      return [
        {
          itemType: "select",
          prop: "classId",
          placeholder: this.$t("el.courseClassManage.classRules"),
          label: this.$t("el.courseClassManage.chooseClass"),
          options: this.classList,
          labelWidth: this.$t("el.courseClassManage.chooseInputLength"),
          clearable: true,
          itemStyle: "width:260px;",
          maxlength: 10,
          formStyle: "width:100%;box-sizing: border-box;",
        },
        {
          itemType: "cascader",
          prop: "studentIds",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.courseClassManage.chooseStudent"),
          labelWidth: this.$t("el.courseClassManage.chooseInputLength"),
          itemStyle: "width:260px;",
          formStyle: "width:100%;box-sizing: border-box;",
          props: {
            // value: "code",
            // label: "name",
            // children: "children",
            multiple: true,
          },
          options: this.studentTree,
        },
      ];
    },
    columns() {
      return [
        {
          label: this.$t("el.courseClassManage.studentName"),
          prop: "studentName",
        },
        {
          label: this.$t("el.courseClassManage.studentID"),
          prop: "studentCode",
          lineRule: true,
          showtooltip: "false", // 传字符串‘false’时候不显示默认的
          width: 80,
        },
        {
          label: this.$t("el.courseClassManage.studentNumber"),
          prop: "studentNumber",
          width: 110,
        },
        // {
        //   label: this.$t("el.courseClassManage.schoolName"),
        //   prop: "schoolName",
        //   lineRule: true,
        //   showtooltip: "false", // 传字符串‘false’时候不显示默认的
        // },
        {
          label: this.$t("el.courseClassManage.gradeName"),
          prop: "gradeName",
        },
        {
          label: this.$t("el.courseClassManage.schoolClassName"),
          prop: "schoolClassName",
          width: 120,
        },
        {
          label: this.$t("el.courseClassManage.currentClass"),
          prop: "className",
        },
        {
          label: this.$t("el.courseClassManage.createTime"),
          prop: "createTime",
          lineRule: true,
          showtooltip: "false", // 传字符串‘false’时候不显示默认的
          width: 100,
          // formatter(row, column, value) {
          //   return row.startTime + "~" + row.endTime;
          // },
        },
        // {
        //   label: '是否计分',
        //   prop: 'enable',
        //   formatter(row, column, value) {
        //     return (
        //       {
        //         0: '否',
        //         1: '是'
        //       }[value] || ''
        //     );
        //   }
        // },
        {
          label: this.$t("el.common.operate"),
          slotName: "otherHandle",
          width: "100px",
        },
      ];
    },
    currentTitle() {
      return this.$route.meta.title;
    },
    routerTitle() {
      return this.className + this.$t(this.currentTitle);
    },
  },
  methods: {
    async init() {
      this.fromTitle =
        this.$route.query.fromTitle ||
        this.$t("el.courseClassManage.studentManage");
      this.courseId = this.$route.query.courseId || "";
      this.schoolCode = this.$route.query.schoolCode || "";
      this.schoolName = this.$route.query.schoolName || "";
      this.className = this.$route.query.className || "";
      this.classId = this.$route.query.classId || "";
      this.tenantInfoId = this.$route.query.tenantInfoId || "";

      let currentTitle = this.$route.meta.title;
      this.routerTitleList = [
        {
          name: this.fromTitle,
        },
        {
          name: currentTitle,
        },
      ];
      // this.routerTitle = this.className + currentTitle;
      this.getClassInfoList(this.schoolCode, this.tenantInfoId);
      this.$nextTick(() => {
        this.$refs["xmTable"].queryParams.classId = this.classId;
        this.$refs["xmTable"].query();
      });
    },
    /**
     * 获取列表数据的请求方法
     */
    fatchMethod(params) {
      let data = {
        schoolCode: this.schoolCode,
        courseId: this.$route.query.tenantInfoId || "",
        classId: params.classId,
        keyword: params.keyword,
        offset: params.offset,
        limit: params.limit,
      };
      console.log("data", data);
      return this.$store
        .dispatch("getCourseClassPage", this.handparams(data))
        .then((result) => {
          return result;
        });
    },
    // 关闭 学生弹框
    closeStudentDialog() {
      this.showStudentDialog = false;
    },
    // 新增学生
    submitStudentForm(refFormName, dialogForm) {
      if (this.canCommit) {
        this.canCommit = false;
        this.allLoading = true;
        refFormName.validate(async (valid) => {
          if (valid) {
            console.log("dialogForm", dialogForm);
            // dialogForm.studentIds = [dialogForm.studentIds[0][3]];
            let studentIds = [];
            console.log("dialogForm.studentIds", dialogForm.studentIds);
            if (dialogForm.studentIds.length > 0) {
              dialogForm.studentIds.forEach((element) => {
                studentIds.push(element[3]);
              });
            }
            let params = {
              classId: dialogForm.classId,
              studentIds: studentIds,
              schoolCode: this.schoolCode,
              courseId: this.courseId,
            };

            await this.addCourseStudent(params);
            await this.getClassInfoList(this.schoolCode, this.tenantInfoId);
            this.canCommit = true;
            this.allLoading = false;
          } else {
            this.canCommit = true;
            this.allLoading = false;
            return false;
          }
        });
      }
    },
    // 新增学生
    addCourseStudent(params) {
      return this.$store
        .dispatch("addStudentClass", this.handparams(params))
        .then((result) => {
          console.log("result", result);
          if (result.success) {
            this.$message.success(this.$t("el.courseClassManage.addSuccess"));
            this.$refs["dialogStudent"].closeDialog();
            this.query();
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },

    /**
     * 筛选
     */
    query() {
      // console.log("this.$refs", this.$refs["xmTable"]);
      this.$refs["xmTable"].query();
    },

    // 新建学生
    async addStudent() {
      this.optionsData = {
        dialogTitle: this.$t("el.courseClassManage.addStudent"),
      };
      this.canCommit = true;
      this.showStudentDialog = true;
      let classItem = this.classList.find(
        (classObj) => classObj.id === this.classId
      );
      if (classItem) {
        this.$refs["dialogStudent"].queryParams = {
          classId: classItem.id,
        };
      } else {
        let classId = this.classList[0] && this.classList[0].id;
        this.$refs["dialogStudent"].queryParams = {
          classId: classId,
          // studentIds: [ ["101101","101101-1","101101-1-1","101101-1-1-5"] ]
        };
      }
      this.$nextTick(() => {
        this.$refs["dialogStudent"].$refs["form"].clearValidate();
      });
      await this.getCourseClassTree();
      this.getSelectedStudentList();
    },
    // 默认选中的学生数据
    getSelectedStudentList() {
      return this.$store
        .dispatch("getSelectedStudentList", {
          schoolCode: this.schoolCode,
          courseId: this.courseId,
        })
        .then((result) => {
          console.log("getSelectedStudentList", result);
          if (result.data && result.data instanceof Array) {
            let defaultChoseStudent = [];
            result.data.forEach((element) => {
              let a = [];
              a.push(element.code2);
              a.push(element.code3);
              a.push(element.code4);
              defaultChoseStudent.push(a);
            });
            console.log("defaultChoseStudent", defaultChoseStudent);
            this.$nextTick(() => {
              this.$set(
                this.$refs["dialogStudent"].queryParams,
                "studentIds",
                defaultChoseStudent
              );
              // this.$refs["dialogStudent"].queryParams.studentIds = defaultChoseStudent;
              console.log(
                "studentIds",
                this.$refs["dialogStudent"].queryParams.studentIds
              );
            });
          }
        })
        .catch(() => {});
    },

    // 学生树
    getCourseClassTree() {
      return this.$store
        .dispatch("getCourseClassTree", {
          schoolCode: this.schoolCode,
          courseId: this.courseId,
        })
        .then((result) => {
          console.log("getCourseClassTree", result);
          if (result.data && result.data instanceof Array) {
            this.convertTree(result.data);
            console.log("result.data", result.data);

            this.studentTree = result.data;
          } else {
            this.studentTree = [];
          }
        })
        .catch(() => {
          this.studentTree = [];
        });
    },

    convertTree(data) {
      data.forEach((element) => {
        element.label = element.name;
        element.value = element.code;

        if (element.children && element.children.length > 0) {
          this.convertTree(element.children);
        } else {
          if (
            element.level === 3 ||
            element.level === 2 ||
            element.level === 1
          ) {
            element.disabled = true;
          }
        }
        if (element.level === 4) {
          if (element.enable) {
            element.disabled = true;
          } else {
            element.disabled = false;
          }
        }
      });
    },

    // 获取学校列表
    async getClassInfoList(schoolCode, courseId) {
      await this.$store
        .dispatch("getClassInfoList", { schoolCode, courseId })
        .then((result) => {
          if (result && result.data instanceof Array) {
            this.classList = result.data;
            this.classList.forEach((element) => {
              element.value = element.id;
              element.label = element.className;
            });
          } else {
            this.classList = [];
          }
        })
        .catch(() => {
          this.classList = [];
        });
    },
    // 移除学生
    deleteAttendClass(item) {
      this.$confirm(
        this.$t("el.courseClassManage.confirmThat") +
          `${item.studentName}` +
          this.$t("el.courseClassManage.confirmContext"),
        this.$t("el.courseClassManage.reminder"),
        {
          confirmButtonText: this.$t("el.courseClassManage.remove"),
          cancelButtonText: this.$t("el.common.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          this.$store
            .dispatch("deleteStudentClass", item.id)
            .then(async (result) => {
              if (result.success) {
                this.$message.success(
                  this.$t("el.courseClassManage.removeSuccess")
                );
                this.$refs["xmTable"].query();
                await this.getClassInfoList(this.schoolCode, this.tenantInfoId);
              } else {
                this.$message({
                  type: "error",
                  message: result.message,
                });
              }
            })
            .catch(() => {
              // this.$message.error("删除失败！" + err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("el.courseClassManage.cancelRemove"),
          });
        });
    },
    // 参数处理
    handparams(param) {
      const params = JSON.parse(JSON.stringify(param));
      for (const i in params) {
        if (params[i] === "") {
          delete params[i];
        }
      }
      // console.log("data===" + JSON.stringify(params))
      return params;
    },
  },
};
</script>

<style lang="less" scoped>
.course-class-student {
  background: #fff;
  padding: 27px 40px 42px 40px;
  border-radius: 4px;
  min-height: 565px;
  box-sizing: border-box;
  .right-list {
    width: 100%;
    padding-top: 20px;
    /deep/ .el-form--inline {
      display: flex;
      // flex-direction: row-reverse;
      width: 100%;
      justify-content: space-between;
    }
    /deep/ .el-form-item {
      margin-bottom: 10px;
      margin-left: 0 !important;
    }
  }
}
</style>
